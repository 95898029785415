import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 

const English = () => {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    setOpen(true);
  }, []);

  const closeModal = () => setOpen(false);

  return (
    <div className='container'>
      <iframe src="https://app.sigmacomputing.com/embed/5Z8eBMPUChVsYwS3gDtv3a" frameborder="0" width="100%" height="100%"></iframe>
      <div className="disable-click">1</div>
      <Popup open={open} closeOnDocumentClick onClose={closeModal}>
        <div className="modal">
          <button className="close" onClick={closeModal}>
            <i className="fas fa-times"></i>
          </button>
          <div className="content">
            You have accessed our public mapping tool which provides limited functionality.
            COMMB’s comprehensive mapping platform is available exclusively to COMMB members in{' '}
            <a href="https://roadmap.commb.ca/mapping" target="_blank" rel="noopener noreferrer">ROADMAP</a>. 
            {' '}Membership inquiries email: <a href="mailto:support@commb.ca">support@commb.ca</a>.
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default English;
