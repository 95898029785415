import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 

const French = () => {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    setOpen(true);
  }, []);

  const closeModal = () => setOpen(false);

  return (
    <div className='container'>
      <iframe 
        src="https://app.sigmacomputing.com/embed/7J402i56ZXYIu7zjnFyx9d" 
        frameborder="0" 
        width="100%" 
        height="100%">
      </iframe>
      
      <Popup open={open} closeOnDocumentClick onClose={closeModal}>
        <div className="modal">
          <button className="close" onClick={closeModal}>
            <i className="fas fa-times"></i>
          </button>
          <div className="content">
            Vous avez accédé à notre outil de cartographie public qui offre des fonctionnalités limitées. La plateforme de cartographie complète de COMMB est disponible exclusivement pour les membres de COMMB dans{' '}
            <a href="https://roadmap.commb.ca/mapping" target="_blank" rel="noopener noreferrer">ROADMAP</a>. 
            {' '}Pour les demandes d'adhésion, envoyez un courriel à: <a href="mailto:support@commb.ca">support@commb.ca</a>.
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default French;
