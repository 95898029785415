import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route, BrowserRouter } from 'react-router-dom';
import English from './pages/English';
import French from './pages/French';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setAnalyticsCollectionEnabled  } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAZushyXHHR_2XLuQocO9PT_DMDpNFv_y4",
  authDomain: "webmappingtool.firebaseapp.com",
  projectId: "webmappingtool",
  storageBucket: "webmappingtool.appspot.com",
  messagingSenderId: "383601850323",
  appId: "1:383601850323:web:7028581abe4e117c966126",
  measurementId: "G-3DVFGDTWMV"
};

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);
setAnalyticsCollectionEnabled(analytics, true); // Ensure analytics collection is enabled

// Log a custom event (example: notification received)
logEvent(analytics, 'notification_received');
// Ensure that Firebase and Firebase Analytics are initialized correctly
function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<English />}/>
        <Route path='/en' element={<English />}/>
        <Route path='/fr' element={<French />} />
      </Routes>
    </Router>
  );
}

export default App;
